// placeholder
@mixin placeholder {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

// transition
@mixin transition($x) {
    transition: $x;
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
}

// transform
@mixin transform($x) {
    transform: $x;
    -webkit-transform: $x;
    -moz-transform: $x;
    -ms-transform: $x;
    -o-transform: $x;
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// gradient
@mixin gradient-bg() {
    background-color: rgb(233,77,4);
    background-image: linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -moz-linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
}
@mixin gradient-bg-hover() {
    background-color: rgb(238,168,73);
    background-image: linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -moz-linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -webkit-linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -ms-linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
}